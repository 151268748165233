import { Auth, I18n } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
export interface StripePricingTableProps {
    lead: string;
    userId: string;
    email: string;
    pricingTableId?: string;
    publishableKey?: string;
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

const StripePricingTable = (props: any) => {

    const pricingTableIdTest = "prctbl_1O6RWvBM13D0hk4S1yiI7OFF";
    const publishableKeyTest = "pk_test_51O3vFABM13D0hk4SoODDKco6sjsSGHAOFCRjnPBtq63KMDcVTZsSGOmGWWviVhglGs9FSCMU8xSSqdkQ4BXshQwD00O8UaX5MO";

    const pricingTableId = "prctbl_1O6Rs1BM13D0hk4SjXTfpLQi";
    const publishableKey = "pk_live_51O3vFABM13D0hk4SdE5yGBciiujuIqbm8B7T7yUg2NDr01CTZKVzxpp5GwA5Qn9Vg8HgZ2auPkLmf61Oog1e3LlX00A4dXiPhx";

    const [userId, setUserId] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    useEffect(() => {
        Promise.resolve(Auth.currentUserInfo()).then((userInfo: any) => {
            if (userInfo && userInfo.attributes) {
                setUserId(userInfo.attributes.sub);
                setEmail(userInfo.attributes.email);
            }
        });
    }, []);
    return (
        <div>
            <h2 className="text-center">
                {I18n.get(props.lead)}

            </h2>

            <stripe-pricing-table
                pricing-table-id={pricingTableId}
                publishable-key={publishableKey}
                client-reference-id={userId}
                customer-email={email}>
            </stripe-pricing-table>
        </div>
    );
};
export default withTranslation()(StripePricingTable);
