import React from 'react';
import { Badge } from 'reactstrap';

export const HealthScoreDisplay: React.FC<{ healthScore: number }> = ({ healthScore }) => {
    let color = '';
    if (healthScore < 4) {
        color = 'danger';
    } else if (healthScore < 7) {
        color = 'warning';
    } else {
        color = 'success';
    }

    return (
        <div className='m-2'>
            <Badge color={color} style={{ fontSize: '1.8em' }}>
                {healthScore.toFixed(0)}
            </Badge>
            <span style={{ fontSize: '1.5em', color: '#888' }}> / </span>
            <span style={{ fontSize: '1.5em' }}>10</span>
        </div>
    );
};

export default HealthScoreDisplay;
