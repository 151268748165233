import { Recipe } from "src/pages/RecipeAnalyzer/Interfaces/RecipeInterface";

export const sampleRecipeData: Recipe = {
    name: "Chicken Broccoli Stir Fry",
    score: 8,
    servings: 4,
    "recipeAnalysis": {
        "totalCalories": "637",
        "calories": "159",
        "fat": { "unit": "g", "value": 3.97 },
        "saturatedFat": { "unit": "g", "value": 0.67 },
        "salt": { "unit": "mg", "value": 291.77 },
        "sugar": { "unit": "g", "value": 0.21 },
        "protein": { "unit": "g", "value": 27.89 },
        "carbohydrates": { "unit": "g", "value": 1.43 },
        "cholesterol": { "unit": "mg", "value": 44.23 },
        "fiber": { "unit": "g", "value": 0.25 },
        "vitc": { "unit": "mg", "value": 5.64 },
        "calcium": { "unit": "mg", "value": 11.23 },
        "iron": { "unit": "mg", "value": 0.98 },
        "potassium": { "unit": "mg", "value": 525.87 }
    },
    "ingredientsAnalysis": [
        {
            "food": "chicken breast",
            "quantity": 4,
            "measure": "whole",
            "calories": {
                "unit": "kcal",
                "value": 326.40
            },
            "fat": {
                "unit": "kcal",
                "value": 7.13
            },
            "salt": {
                "unit": "mg",
                "value": 122.40
            },
            "sugar": {
                "unit": "g",
                "value": 0.00
            },
            "protein": {
                "unit": "g",
                "value": 61.20
            },
            "carbohydrates": {
                "unit": "g",
                "value": 0.00
            }
        },
        {
            "food": "olive oil",
            "quantity": 2,
            "measure": "teaspoon",
            "calories": {
                "unit": "kcal",
                "value": 19.89
            },
            "fat": {
                "unit": "kcal",
                "value": 2.25
            },
            "salt": {
                "unit": "mg",
                "value": 0.04
            },
            "sugar": {
                "unit": "g",
                "value": 0.00
            },
            "protein": {
                "unit": "g",
                "value": 0.00
            },
            "carbohydrates": {
                "unit": "g",
                "value": 0.00
            }
        },
        {
            "food": "can diced tomato",
            "quantity": 14.5,
            "measure": "ounce",
            "calories": {
                "unit": "kcal",
                "value": 16.44
            },
            "fat": {
                "unit": "kcal",
                "value": 0.26
            },
            "salt": {
                "unit": "mg",
                "value": 10.28
            },
            "sugar": {
                "unit": "g",
                "value": 2.62
            },
            "protein": {
                "unit": "g",
                "value": 0.81
            },
            "carbohydrates": {
                "unit": "g",
                "value": 3.57
            }
        },
        {
            "food": "sundried tomato pesto",
            "quantity": 0.25,
            "measure": "cup",
            "calories": {
                "unit": "kcal",
                "value": 36.83
            },
            "fat": {
                "unit": "kcal",
                "value": 2.83
            },
            "salt": {
                "unit": "mg",
                "value": 104.83
            },
            "sugar": {
                "unit": "g",
                "value": 0.85
            },
            "protein": {
                "unit": "g",
                "value": 0.28
            },
            "carbohydrates": {
                "unit": "g",
                "value": 1.98
            }
        },
        {
            "food": "marinated artichoke",
            "quantity": 14,
            "measure": "ounce",
            "calories": {
                "unit": "kcal",
                "value": 89.30
            },
            "fat": {
                "unit": "kcal",
                "value": 8.93
            },
            "salt": {
                "unit": "mg",
                "value": 312.55
            },
            "sugar": {
                "unit": "g",
                "value": 1.53
            },
            "protein": {
                "unit": "g",
                "value": 2.29
            },
            "carbohydrates": {
                "unit": "g",
                "value": 5.95
            }
        }
    ]
}

export const sampleSummary = `This Chicken Broccoli Stir Fry is a low-calorie and low-fat dish, with only
159 calories and 3.97 grams of fat per serving. It is also a good source of protein,
providing 27.89 grams per serving. The dish is low in carbohydrates, with only 1.43 grams per serving.
It contains a small amount of sodium, with 291.77 milligrams per serving, which is about 13% of the daily
recommended value of 2,300 milligrams. Additionally, it is low in sugar and a good source of vitamin C,
providing 5.64 milligrams per serving. Overall, this stir fry is a nutritious and balanced option for a meal.`;
