import React from "react";
import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const TOS = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Pages" breadcrumbItem="Terms of Service" />
                    <h1>RecipeCalorieAnalyzer Terms of Service</h1>
                    <p><strong>Last Updated:</strong> October 28, 2023</p>

                    <h2>1. Acceptance of Terms</h2>
                    <p>By accessing or using the RecipeCalorieAnalyzer website (https://RecipeCalorieAnalyzer.com), you agree to these Terms of Service. If you do not agree to these terms, please do not use the website.</p>

                    <h2>2. Disclaimer of Warranty</h2>
                    <p>The RecipeCalorieAnalyzer website is provided "as is" without any warranties or representations of any kind. We do not guarantee the accuracy, completeness, or reliability of the content on this website. Your use of the website is at your own risk.</p>

                    <h2>3. Indemnification</h2>
                    <p>You agree to indemnify and hold RecipeCalorieAnalyzer and its affiliates, officers, employees, and agents harmless from and against any claims, liabilities, damages, losses, and expenses, including legal fees, arising out of your use of our website or violation of these Terms of Service.</p>

                    <h2>4. Limitation of Liability</h2>
                    <p>RecipeCalorieAnalyzer, its affiliates, and its partners shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from your use of the website or the functionality it provides. This includes, but is not limited to, any loss of data, profits, or business interruption.</p>

                    <h2>5. Ownership of Data</h2>
                    <p>When you purchase a package from RecipeCalorieAnalyzer, you can upload your documents in image or PDF format for conversion to Excel or CSV. You retain ownership of any data you upload.</p>

                    <h2>6. User Data Collection</h2>
                    <p>We collect the following user data:</p>
                    <ul>
                        <li>Name</li>
                        <li>Email</li>
                        <li>Documents you upload</li>
                        <li>Payment information</li>
                    </ul>

                    <h2>7. Non-Personal Data Collection</h2>
                    <p>We may use web cookies to collect non-personal data to enhance your experience on our website.</p>

                    <h2>8. Payment Processing</h2>
                    <p>We use Stripe to handle payments. By using our services, you agree to comply with Stripe's Privacy Policy (<a href="https://stripe.com/privacy">https://stripe.com/privacy</a>) and Terms of Service (<a href="https://stripe.com/legal/consumer">https://stripe.com/legal/consumer</a>).</p>

                    <h2>9. Dispute Resolution</h2>
                    <p>Any disputes related to these Terms of Service must be resolved through arbitration, with the maximum claim amount not exceeding $5. The jurisdiction for arbitration is Austin, Texas.</p>

                    <h2>10. Governing Law</h2>
                    <p>These Terms of Service are governed by the laws of the United States, specifically the state of Texas.</p>

                    <h2>11. Updates to the Terms</h2>
                    <p>Users will be notified of any updates to these Terms of Service via email.</p>

                    <p>If you have any questions or concerns regarding these terms, please contact us at <a href="mailto:RecipeCalorieAnalyzer@gmail.com">RecipeCalorieAnalyzer@gmail.com</a>.</p>

                    <p>Thank you for using RecipeCalorieAnalyzer!</p>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default TOS;
