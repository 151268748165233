import React, { useEffect, useRef, useState } from 'react';
import { Recipe } from '../Interfaces/RecipeInterface';
import { Alert, Button, Col, Row, Spinner } from 'reactstrap';
import * as d3 from 'd3';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { layoutTheme } from 'src/constants/layout';
import HealthScoreDisplay from './HealthScoreDisplay';
import { checkUserAuthentication } from 'src/utils/Utils';
import { useNavigate } from 'react-router';
import { executeCall } from 'src/helpers/aws/aws_apigateway_helper';
import { getWindowDimensions } from '../helper';
import { sampleSummary } from 'src/common/data/sampleRecipe';

type NutritionData = {
    label: string;
    value: number;
};

const NutritionLabel: React.FC<{ recipe: Recipe, isSample?: boolean }> = ({ recipe, isSample }) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const [nutritionSummary, setNutritionSummary] = useState<any>(null);

    const recipeAnalysis = recipe.recipeAnalysis;
    const [showChart, setShowChart] = useState(true);

    const nonauthData = createSelector(

        (state: any) => state.Layout,
        (layout) => ({
            layoutMode: layout.layoutMode
        })
    );
    // Inside your component
    const { layoutMode } = useSelector(nonauthData);

    // Reference to the SVG container
    const svgRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        const handleResize = () => {
            const windowDimensions = getWindowDimensions();
            if (windowDimensions.width >= 600) {
                setShowChart(true);
            } else {
                setShowChart(false);
                return
            }

            renderSummaryBarChart();
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        setNutritionSummary(null);
    }, [recipe])

    useEffect(() => {
        return renderSummaryBarChart();
    }, [recipe, layoutMode]);

    const renderSummaryBarChart = () => {

        const textColor = layoutMode === layoutTheme["DARKMODE"] ? "#FFFFFF" : "#000000";

        // Create a tooltip div outside the SVG
        const tooltip = d3.select("body")
            .append("div")
            .style('background-color', 'rgba(0, 0, 0, 0.7)') // Semi-transparent background
            .style('padding', '8px') // Padding for the tooltip
            .style('border-radius', '5px') // Rounded corners
            .style('color', textColor)
            .style('font-size', '16px') // Increase font size
            .attr("class", "tooltip");

        if (svgRef.current) {
            let percentOfSvgWidth = 0.5;
            if (svgRef.current.clientWidth <= 360) {
                percentOfSvgWidth = 1.38;
            } else if (svgRef.current.clientWidth <= 400) {
                percentOfSvgWidth = 1.2;
            }
            let addToWidth = 0;
            if (svgRef.current.clientWidth < 580) {
                addToWidth = svgRef.current.clientWidth * percentOfSvgWidth;
            }
            const svgWidth = svgRef.current.clientWidth + addToWidth;
            const svgHeight = svgRef.current.clientHeight;

            const fatInGrams = recipeAnalysis.fat.value / recipe.servings;
            const carbsInGrams = recipeAnalysis.carbohydrates.value / recipe.servings;
            const proteinInGrams = recipeAnalysis.protein.value / recipe.servings;

            const data: NutritionData[] = [
                { label: 'Fat', value: fatInGrams },
                { label: 'Carbs', value: carbsInGrams },
                { label: 'Protein', value: proteinInGrams },
            ];

            const svg: d3.Selection<SVGSVGElement, unknown, null, undefined> = d3.select(svgRef.current);
            svg.selectAll('*').remove(); // Clear existing visuals


            // Margins for the SVG
            const margin = { top: 50, right: 300, bottom: 40, left: 60 };
            const width = svgWidth - margin.left - margin.right;
            const height = svgHeight - margin.top - margin.bottom;
            const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

            g.append("text")
                .attr("x", width / 2 + margin.left)  // Position the title in the center of the plotting area
                .attr("y", -10)  // Adjust this value to move the title up above the top edge of the plotting area
                .attr("text-anchor", "middle")  // Ensures the title is centered
                .text("Macro Nutrients")
                .style("font-size", "28px")  // Adjust font size as needed
                .attr("fill", textColor)
                .attr("font-weight", "bold");  // Bold the title text

            // Scales
            const xScale = d3.scaleBand().domain(data.map(d => d.label)).rangeRound([0, width]).padding(0.2);
            const yScale = d3.scaleLinear().domain([0, d3.max(data, d => d.value) || 0]).range([height, 0]);

            // Colors
            const colorScale = d3.scaleOrdinal(d3.schemeCategory10).domain(data.map(d => d.label));

            // Bars
            g.selectAll('rect')
                .data(data)
                .enter()
                .append('rect')
                .attr('x', d => xScale(d.label)!)
                .attr('y', d => yScale(d.value))
                .attr('width', xScale.bandwidth())
                .attr('height', d => height - yScale(d.value))
                .attr('fill', d => colorScale(d.label))
                .on('mouseover', function (e, d) {
                    d3.select(this).attr('opacity', 0.7);
                    tooltip.transition().duration(50).style("opacity", 1);
                    tooltip.html(`${d.label}: ${d.value.toFixed(2)}`)
                        .style("left", (e.pageX + 5) + "px")
                        .style("top", (e.pageY - 28) + "px");
                })
                .on('mouseout', function () {
                    d3.select(this).attr('opacity', 1);
                    tooltip.transition().duration(50).style("opacity", 0);
                });

            // Y-Axis
            g.append("g")
                .call(d3.axisLeft(yScale))
                .selectAll("text")
                .style("font-size", "16px")
                .attr("fill", textColor);

            // Y-Axis Label
            g.append("text")
                .attr("transform", "rotate(-90)")  // Rotate text to be vertical
                .attr("y", 0 - margin.left)  // Position to the left of the Y-axis
                .attr("x", 0 - (height / 2))  // Centered vertically
                .attr("dy", "1em")  // Adjust distance from the axis
                .style("text-anchor", "middle")
                .text("grams")
                .style("font-size", "16px")
                .attr("fill", textColor);

            data.forEach(d => {
                g.append("text")
                    .attr("x", xScale(d.label)! + (xScale.bandwidth() / 2))  // Centered below each bar
                    .attr("y", height + 20)  // Adjust the distance from the bottom of the bars
                    .style("text-anchor", "middle")
                    .text(d.label)
                    .style("font-size", "14px")
                    .attr("fill", textColor);
            });

            // Legend
            const legend = svg.append("g").attr("transform", `translate(${width + margin.left + 50}, ${margin.top + 20})`);
            data.forEach((d, i) => {
                const legendGroup = legend.append("g").attr("transform", `translate(0,${i * 30})`);
                legendGroup.append("rect").attr("width", 20).attr("height", 20).attr("fill", colorScale(d.label));
                legendGroup.append("text")
                    .attr("x", 30)
                    .attr("y", 15)
                    .text(`${d.label} (grams): ${d.value.toFixed(2)}`)
                    .style("font-size", "16px")
                    .attr("fill", textColor);
            });
            legend.append("text")
                .attr("x", 0)
                .attr("y", -5)  // Position the title slightly above the first legend item
                .text("Macros (Per serving)")
                .style("font-size", "18px")  // Adjust the font size as needed
                .attr("fill", textColor)
                .attr("font-weight", "bold");  // Make the title bold
        }

        return () => {
            tooltip.remove();
        };
    }

    // Function to handle the print action
    // Function to handle the print action
    const handlePrint = () => {
        const printWindow = window.open('', '', 'width=600,height=600');
        printWindow?.document.open();
        printWindow?.document.write('<html><head><title>Nutrition Label</title></head><body>');
        printWindow?.document.write('<style>@media print{header, footer{display:none}}</style>'); // Hide header and footer
        printWindow?.document.write('<div class="card nutritionFactsCard p-3">');

        // Copy the card contents to the print window
        const cardContents = document.querySelector('.nutritionFactsCard')?.innerHTML;
        printWindow?.document.write(cardContents || "");

        printWindow?.document.write('</div></body></html>');
        printWindow?.document.close();
        printWindow?.print();
        printWindow?.close();
    };

    // Calculate %DV values based on daily values (you need to define these)
    const dailyValue: Record<string, number> = {
        fat: 65,                // grams
        sodium: 2300,           // milligrams
        carbohydrates: 300,     // grams
        protein: 50,            // grams
        totalCalories: 2000,    // calories
        sugar: 37.5,            // grams
        saturatedFat: 20,       // grams (general guideline, adjust as needed)
        cholesterol: 300,       // milligrams (general guideline, adjust as needed)
        fiber: 25,              // grams (general guideline for adults, adjust as needed)
        vitc: 90,               // milligrams (general guideline for adult males, adjust based on gender/age)
        calcium: 1000,          // milligrams (general guideline for adults, adjust as needed)
        iron: 18,               // milligrams (general guideline for adult females, adjust based on gender/age)
        potassium: 4700,        // milligrams (general guideline, adjust as needed)
    };



    const calculatePercentDV = (nutrientValue: number, nutrientName: string) => {
        return ((nutrientValue / dailyValue[nutrientName]) * 100).toFixed(2);
    };

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [visibleSuccess, setVisibleSuccess] = useState(true);
    const [visibleError, setVisibleError] = useState(true);

    let navigate = useNavigate();

    useEffect(() => {
        checkUserAuthentication().then((isAuthentcated) => {
            setIsAuthenticated(isAuthentcated);
            performActionsOnAuthenticate();
        });
    }, [])



    function performActionsOnAuthenticate() {
        fetchNutritionSummary()
    }

    function fetchNutritionSummary() {
        if (!isAuthenticated) {
            navigate("/dashboard");
            return;
        }

        setLoading(true);
        setSuccessMessage('');
        setErrorMessage('');

        function handleFetchNutritionSummarySuccess(summary: string) {
            setNutritionSummary(summary);
            setLoading(false);
            setSuccessMessage('Recipe summary fetched successfully!');
        }

        if (isSample) {
            handleFetchNutritionSummarySuccess(sampleSummary);
            return;
        }

        executeCall("/analyze/summary", {
            "dishName": recipe.name,
            "nutritionFacts": recipe.recipeAnalysis,
        }).then((response: any) => {
            handleFetchNutritionSummarySuccess(response.body.summary);
        }).catch((error: any) => {
            console.log(error);
            setLoading(false);
            setErrorMessage('Error fetching recipe summary.');
        });
    }

    const NutrionFacts = () => (

        <Row className='mb-4'>
            <div className="row">
                <div className="col-8">
                    <strong className='font-size-16'>Serving Size:</strong> 1 serving
                </div>
                <div className="col-4 text-right">
                    <strong className='font-size-16'>Total Servings:</strong> {recipe.servings}
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-8">
                    <strong className='font-size-16'>Amount Per Serving</strong>
                </div>
                <div className="col-4 text-right">
                    <strong className='font-size-16'>% Daily Value*</strong>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-8">
                    <strong className='font-size-16'>Calories</strong> {recipeAnalysis.calories}
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-8">
                    <strong className='font-size-16'>Total Fat</strong> {recipeAnalysis.fat.value}g
                </div>
                <div className="col-4 text-right">
                    {calculatePercentDV(recipeAnalysis.fat.value, 'fat')}%
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-8">
                    <strong className='font-size-16'>Sodium</strong> {recipeAnalysis.salt.value}mg
                </div>
                <div className="col-4 text-right">
                    {calculatePercentDV(recipeAnalysis.salt.value, 'sodium')}%
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-8">
                    <strong className='font-size-16'>Total Carbohydrates</strong> {recipeAnalysis.carbohydrates.value}g
                </div>
                <div className="col-4 text-right">
                    {calculatePercentDV(recipeAnalysis.carbohydrates.value, 'carbohydrates')}%
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-8">
                    <strong className='font-size-16'>Sugar</strong> {recipeAnalysis.sugar.value}g
                </div>
                <div className="col-4 text-right">
                    {calculatePercentDV(recipeAnalysis.sugar.value, 'sugar')}%
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-8">
                    <strong className='font-size-16'>Protein</strong> {recipeAnalysis.protein.value}g
                </div>
                <div className="col-4 text-right">
                    {calculatePercentDV(recipeAnalysis.protein.value, 'protein')}%
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-8">
                    <strong className='font-size-16'>Saturated Fat</strong> {recipeAnalysis.saturatedFat.value}g
                </div>
                <div className="col-4 text-right">
                    {calculatePercentDV(recipeAnalysis.saturatedFat.value, 'saturatedFat')}%
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-8">
                    <strong className='font-size-16'>Cholesterol</strong> {recipeAnalysis.cholesterol.value}mg
                </div>
                <div className="col-4 text-right">
                    {calculatePercentDV(recipeAnalysis.cholesterol.value, 'cholesterol')}%
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-8">
                    <strong className='font-size-16'>Dietary Fiber</strong> {recipeAnalysis.fiber.value}g
                </div>
                <div className="col-4 text-right">
                    {calculatePercentDV(recipeAnalysis.fiber.value, 'fiber')}%
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-8">
                    <strong className='font-size-16'>Vitamin C</strong> {recipeAnalysis.vitc.value}mg
                </div>
                <div className="col-4 text-right">
                    {calculatePercentDV(recipeAnalysis.vitc.value, 'vitc')}%
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-8">
                    <strong className='font-size-16'>Calcium</strong> {recipeAnalysis.calcium.value}mg
                </div>
                <div className="col-4 text-right">
                    {calculatePercentDV(recipeAnalysis.calcium.value, 'calcium')}%
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-8">
                    <strong className='font-size-16'>Iron</strong> {recipeAnalysis.iron.value}mg
                </div>
                <div className="col-4 text-right">
                    {calculatePercentDV(recipeAnalysis.iron.value, 'iron')}%
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-8">
                    <strong className='font-size-16'>Potassium</strong> {recipeAnalysis.potassium.value}mg
                </div>
                <div className="col-4 text-right">
                    {calculatePercentDV(recipeAnalysis.potassium.value, 'potassium')}%
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-12">
                    <strong className='font-size-16'>* Percent Daily Values are based on a 2,000 calorie diet.</strong>
                </div>
            </div>
        </Row>
    );

    return (
        <div className="card nutritionFactsCard p-3">
            {/* Print button */}
            {successMessage &&
                <Alert color="success" className="mb-4" isOpen={visibleSuccess} toggle={() => setVisibleSuccess(false)}>
                    {successMessage}
                </Alert>}
            {errorMessage &&
                <Alert color="danger" className="mb-4" isOpen={visibleError} toggle={() => setVisibleError(false)}>
                    {errorMessage}
                </Alert>}
            <Row className='mb-2'>
                <h3 className='text-center'>Nutrition Analysis</h3>
                <Col xl={10}>
                    <h2>Dish: {recipe.name}</h2>
                    <h2><HealthScoreDisplay healthScore={recipe.score} /></h2>
                </Col>
                <Col xl={2}>
                    <div className="">
                        <a className="text-secondary hover-pointer font-size-22" onClick={handlePrint}>
                            Print <i className="mdi mdi-download font-size-22" id="deletetooltip" />
                        </a>
                    </div>
                </Col>
            </Row>

            <Row className="justify-content-center">
                <h3 className='text-center'>Nutrition Facts</h3>
                <Row className="justify-content-center">
                    <NutrionFacts />
                </Row>
                {showChart &&
                    <Row className='w-100'>
                        <svg ref={svgRef} width="100%" height="400"></svg>
                    </Row>
                }
            </Row>
            <Row className='justify-content-center'>
                <h3>Nutrition Summary</h3>
                <Row className='mt-2'>
                    <p className='font-size-18'>{nutritionSummary}</p>
                    {!nutritionSummary &&
                        <>
                            <Button
                                color="primary"
                                size="lg"
                                onClick={fetchNutritionSummary}
                                disabled={loading} // Disable the button while loading
                                block
                            >
                                {loading ? (
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Spinner className="d-block m-2" color="warn" />
                                        <span>Fetching Nutrition Summary...</span>
                                    </div>
                                ) : (
                                    <span>Fetch Nutrition Summary</span>
                                )}
                            </Button>

                            <p className="text-muted">To streamline the analysis, nutrition summary is not performed automatically.
                                Click the button above to summarize the recipe nutrition.
                            </p>
                        </>
                    }
                </Row>
            </Row>
        </div>
    );

};

const RecipeComponent: React.FC<{ data: Recipe, isLoading: boolean, isSample?: boolean, ref: any }> = ({ data, isLoading, isSample, ref }) => {
    return (
        <div ref={ref}>
            <div className="container mt-4 position-relative">
                {(isLoading) &&
                    <div className="loading-overlay d-flex justify-content-center align-items-center">
                        <Spinner color="primary" />
                    </div>
                }
                {isSample && <h2> Sample Analysis</h2>}
                <div> {/* Added this wrapping div to make it scrollable */}
                    <NutritionLabel recipe={data} isSample={isSample} />
                </div>
            </div>
        </div>
    );
};


export default RecipeComponent;
