/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "recipeanalyzer",
            "endpoint": "https://dgcldc6lnd.execute-api.us-east-1.amazonaws.com/mainline",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:1c9a9687-13f1-4ecd-8187-3bfa4f712c60",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_YoJ9ubiKE",
    "aws_user_pools_web_client_id": "6tf2rrccistjrj66c1hfvjsmb6",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ovigrecipecalorieanalyzer-mainline",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
