import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CreativeProjectMonth from "./CreativeProjectMonth";
import { checkUserAuthentication } from "src/utils/Utils";
import Paywall from "src/pages/Subscriptions/Paywall";

const monthData = [
  {
    id: 1,
    title: "Basic",
    price: "10",
    desc: "Allows you to analyze 35 recipes per month. Includes 5 bonus recipes for free.",
  },
  {
    id: 2,
    title: "Standard",
    price: "20",
    isFeatured: true,
    desc: "Allows you to analyze 65 recipes per month. Includes 15 bonus recipes for free.",
  },
  {
    id: 3,
    title: "Premium",
    price: "30",
    desc: "Allows you to analyze 120 recipes per month. Includes 30 bonus recipes for free.",
  },
];

const PagePricing = () => {
  const [activeTab, toggleTab] = useState("1");

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    checkUserAuthentication().then((isAuthentcated) => {
      setIsAuthenticated(isAuthentcated);
    });
  }, [])

  return (
    <div className="page-content">
      <Container fluid>
        {isAuthenticated &&
          <>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="RecipeCalorieAnalyzer" breadcrumbItem="Pricing" />
            <Paywall isSubscribed={false} embedded={true} />
          </>
        }
        {!isAuthenticated &&
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Unlock the Full Potential of RecipeCalorieAnalyzer! Subscribe now and analyze your recipes,
                    giving you insights to making healthy food decisions!
                  </h4>
                  <div className="flex-shrink-0 align-self-end">
                    <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames(
                            {
                              active: activeTab === "1",
                            },
                            "px-3 rounded monthly"
                          )}
                          onClick={() => {
                            toggleTab("1");
                          }}
                        >
                          Monthly
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </CardHeader>
                <div className="card-body">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <div className="row d-flex justify-content-center" >
                        <CreativeProjectMonth monthData={monthData} />
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </Card>
            </Col>
          </Row>
        }
      </Container>
    </div >
  );
};

export default PagePricing;
