import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router";
import { checkUserAuthentication } from "src/utils/Utils";

import sectionOneImage from "../../assets/images/landing/1.jpg";
import sectionTwoImage from "../../assets/images/landing/2.jpg";
import sectionThreeImage from "../../assets/images/landing/3.jpg";
import { Link } from "react-router-dom";

const landingPageSections = [
    {
        title: "Recipe Calorie Analyzer: Comprehensive Nutritional & Calorie Breakdown",
        content: "Instantly tap into detailed nutritional insights and precise calorie counts for any recipe—whether you've sourced them online or crafted them in your kitchen.",
        imageUrl: sectionOneImage
    },
    {
        title: "Trustworthy Nutritional Information at Your Fingertips",
        content: "Harness the power of our industry-leading nutrition and calorie calculator, seamlessly integrating verified ingredient data with USDA guidelines, ensuring you receive the most accurate and thorough nutritional data.",
        imageUrl: sectionTwoImage
    },
    {
        title: "Nutritional Insights & Analysis Tailored For Your Recipe",
        content: "Gain a swift overview and in-depth analysis of your recipe. Understand the impact of each nutritional component on your recipe's nutritional profile, and leverage our actionable insights to refine and boost its nutritional value.",
        imageUrl: sectionThreeImage
    }
];


const Section = ({ title, content, imageUrl, isImageLeft }: any) => (
    <Row className="mb-5 no-gutters">
        {isImageLeft && (
            <Col md="6" className="d-flex align-items-center justify-content-center mb-4">
                <div className="d-flex align-items-center justify-content-center">
                    <img src={imageUrl} alt="section" className="img-fluid" />
                </div>
            </Col>
        )}
        <Col md="6">
            <h2 className="large-title">{title}</h2>
            <p className="large-content">{content}</p>
            <Link to="/dashboard" className="large-cta">
                Analyze nutritional value
                <span className="m-2"><i className="dripicons-arrow-thin-right"></i></span>
            </Link>
        </Col>
        {!isImageLeft && (
            <Col md="6" className="d-flex align-items-center justify-content-center mt-4">
                <div className="d-flex align-items-center justify-content-center">
                    <img src={imageUrl} alt="section" className="img-fluid" />
                </div>
            </Col>
        )}
    </Row>
);

const Landing = () => {

    let navigate = useNavigate();

    const [sampleDocumentMenu, setSampleDocumentMenu] = useState(false);

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        checkUserAuthentication().then((isAuthentcated) => {
            setIsAuthenticated(isAuthentcated);
        });
    }, [])

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/dashboard");
        }
    }, [isAuthenticated])

    const toggleSampleDocumentMenu = (e: any) => {
        console.log(e);
        setSampleDocumentMenu(!sampleDocumentMenu);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {landingPageSections.map((section, index) => (
                        <Section
                            key={index}
                            title={section.title}
                            content={section.content}
                            imageUrl={section.imageUrl}
                            isImageLeft={index % 2 === 0}
                        />
                    ))}
                </Container>
            </div>

        </React.Fragment >
    );
}

export default Landing;
