import React, { useEffect, useState } from "react";
import { authRoutes, nonAuthRoutes } from "./routes/allRoutes";
import { Route, Routes } from "react-router-dom";
import VerticalLayout from "./components/VerticalLayout";
import HorizontalLayout from "./components/HorizontalLayout";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import '@aws-amplify/ui-react/styles.css';
// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";
import { Authenticator, View, useTheme } from "@aws-amplify/ui-react";

let components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <a href="/" ><h2 className="text-8xl">
          <img src="/logo192.png" width={"100vw"} />
        </h2></a>
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <p className="text-slate-900">
          <a href="/">RecipeCalorieAnalyzer. </a> &copy; All Rights Reserved.
        </p>
      </View>
    );
  },

  SignIn: {
    Header() {
      return (<></>
      );
    },
  },

  SignUp: {
    Header() {
      return (<></>
      );
    }
  }
};

function hideSplashScreen() {
  let splashScreen = document.getElementById("splash-screen");
  if (splashScreen) {
    splashScreen.style.display = 'none';
  }
}


const App = (props: any) => {
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(_ => {
        hideSplashScreen();
      })
      .catch(() => {
        hideSplashScreen();
      });
  }, []);



  const { layoutType } = useSelector((state: any) => ({
    layoutType: state.Layout.layoutType,
  }));

  function getLayout() {
    let layoutCls: Object = VerticalLayout;
    switch (layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout: any = getLayout();
  return (
    <React.Fragment>
      <Routes>
        {nonAuthRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Layout>{route.component}</Layout>
            }
            key={idx}
          />
        ))}

        {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authenticator components={components}>
                <Layout>{route.component}</Layout>
              </Authenticator>
            }
            key={idx}
          />
        ))}
      </Routes>
    </React.Fragment >
  );
};

export default App;


