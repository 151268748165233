export const esAppDict = {
    'Menu': 'Menu',
    'designed_by': 'Designed & Developed by',
    'Logout': 'Logout',
    'paywall_lead': 'Unlock the Full Potential of RecipeCalorieAnalyzer! Subscribe now and analyze your recipes, giving you insights to making healthy food decisions!',
    'loading': 'Loading...',
    'delete': 'Delete',
    'yes': 'Yes',
    'no': 'No',
    'cancel': 'Cancel',
    'thank_you': 'Thank you',
    'for_subs': 'for Subscribing!',
    'or': "Or",
    "thank_you_start_converting_your_documents": "You have unlocked the Full Potential of RecipeCalorieAnalyzer! Effortless analyze your recipes, giving you insights to making healthy food decisions!"
};
