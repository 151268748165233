import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Button, Col, Container, Input, Row, Alert, CardBody, Card, Spinner, Label } from "reactstrap";
import { executeCall } from "src/helpers/aws/aws_apigateway_helper";
import { replaceAbbreviations } from "../RecipeAnalyzer/helper";
import { Recipe, toRecipe } from "../RecipeAnalyzer/Interfaces/RecipeInterface";
import RecipeComponent from "../RecipeAnalyzer/Components/RecipeComponent";
import { sampleRecipeData } from "src/common/data/sampleRecipe";
import { checkUserAuthentication, convertToListOfStrings } from "src/utils/Utils";
import { useNavigate } from "react-router";
import { set } from "lodash";
import { verifyUsage } from "src/helpers/usage_helper";
import { NotEnougCreditsError, SubscriptionRequiredError } from "src/common/ApiResponseErrors";
import Paywall from "../Subscriptions/Paywall";

const Dashboard = () => {
  document.title = "Recipe Calorie Analyzer";

  const recipeRef = useRef<any>(null);


  const [loading, setLoading] = useState<boolean>(false);
  const [isSample, setIsSample] = useState<boolean>(true);
  const [ingredientList, setIngredientList] = useState('');
  const [inputsEmpty, setInputsEmpty] = useState<boolean>(true); // State to track if inputs are empty

  const [recipe, setRecipe] = useState<Recipe>();
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [dishName, setDishName] = useState<string>('');  // New state variable for the dish name
  const [servings, setServings] = useState<number>(1);  // New state variable for the servings

  const [visibleSuccess, setVisibleSuccess] = useState(true);
  const [visibleError, setVisibleError] = useState(true);

  let navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const [isSubscriptionStatusChecked, setIsSubscriptionStatusChecked] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(true);
  const [recipesRemainingCount, setRecipesRemainingCount] = useState<number>(0);
  const [recipesAllowedPerMonth, setRecipesAllowedPerMonth] = useState<number>(0);
  const [recipesProcessedCount, setRecipesProcessedCount] = useState<number>(0);

  const [showPaywall, setShowPaywall] = useState<boolean>(false);

  useEffect(() => {
    checkUserAuthentication().then((isAuthentcated) => {
      setIsAuthenticated(isAuthentcated);
    });
  }, [])

  useEffect(() => {
    verifyUsage().then((response) => {
      setIsSubscriptionStatusChecked(true);
      setIsSubscribed(true);

      let creditsRemaining = response.body.usage.creditsRemaining;
      console.log(creditsRemaining);
      setRecipesRemainingCount(creditsRemaining);

      let creditsPerMonth = response.body.usage.creditsPerMonth;
      setRecipesAllowedPerMonth(creditsPerMonth);
      setRecipesProcessedCount(creditsPerMonth - creditsRemaining);
    }).catch(error => {
      setIsSubscriptionStatusChecked(true);
      setIsSubscribed(false);
      console.error(error);
    });
  }, []);

  const handleDishNameChange = (event: any) => {
    showLoginToAnalyzeMessage();

    setDishName(event.target.value);
    checkInputsEmpty(event.target.value, ingredientList);
  }

  const handleServingsChange = (event: any) => {
    showLoginToAnalyzeMessage();

    setServings(parseInt(event.target.value));
  }

  const handleChange = (event: any) => {
    showLoginToAnalyzeMessage();

    setIngredientList(event.target.value);
    checkInputsEmpty(dishName, event.target.value);
  }

  const checkInputsEmpty = (dish: string, ingredients: string) => {
    const areInputsEmpty = !dish.trim() || !ingredients.trim();
    setInputsEmpty(areInputsEmpty);
  }

  const showLoginToAnalyzeMessage = () => {
    if (!isAuthenticated) { // If the user is not authenticated, redirect to the dashboard
      setErrorMessage('Please login to start analyzing recipes for free!');
      return;
    };
  }

  const handleRecipeAnalysis = () => {
    if (!isAuthenticated) {
      navigate("/dashboard");
      return;
    }
    setIsSample(true);
    setLoading(true);

    setSuccessMessage('');
    setErrorMessage('');

    executeCall("/analyze", {
      "dishName": dishName,
      "servings": servings,
      "ingredientsList": convertToListOfStrings(replaceAbbreviations(ingredientList)),
    }).then((response: any) => {
      console.log("start: /analyze", response.body)
      setRecipe(toRecipe(dishName, servings, response.body));
      let usageDetails = response.body.usage;
      let creditsRemaining = usageDetails.usage.creditsRemaining || 0;

      console.log(creditsRemaining);
      setRecipesRemainingCount(creditsRemaining);
      setLoading(false);
      setIsSample(false);
      setSuccessMessage('Recipe analyzed successfully!');
      console.log("end: /analyze", response.body)

    }).catch((e: any) => {
      setLoading(false);
      const hasData = e && e.response && e.response.data;
      if (e instanceof SubscriptionRequiredError) {
        setShowPaywall(true);
        setErrorMessage(`You do not have an active subscription. Please subscription to analyze recipes.`);
        return;
      }
      if (e instanceof NotEnougCreditsError) {
        setShowPaywall(true);
        setErrorMessage(`You have reached your monthly recipe analysis limit.
        Please upgrade your subscription to analyze more recipes.`);
        return;
      }
      if (hasData && e.response.status === 400) {
        setErrorMessage(`Recipe analyzer detected low quality recipe information.
        Review the recipe and make sure it is clear and contains standard ingredients with measurements.`
        );
      } else {
        setErrorMessage('Error analyzing recipe. Please try again.');
      }
      console.log(e);
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Recipe Calorie Analyzer" breadcrumbItem="Recipe Calorie Analyzer" />

          {successMessage &&
            <Alert color="success" isOpen={visibleSuccess} toggle={() => setVisibleSuccess(false)}>
              {successMessage}
            </Alert>}
          {errorMessage &&
            <Alert color="danger" isOpen={visibleError} toggle={() => setVisibleError(false)}>
              {errorMessage}
            </Alert>}
          {showPaywall && <Paywall embedded={true} />}
          {!showPaywall &&
            <Row className="">
              <Col xl={4}>
                <Card>
                  <CardBody>
                    <h5 className="font-weight-bold">Enter Your Recipe:</h5>
                    <Label for="dishName">Dish Name</Label>
                    <Input
                      type="text"
                      value={dishName}
                      onChange={handleDishNameChange}
                      className="primary mb-3 rounded"
                      placeholder="Breakfast Sandwich"
                      onClick={() => showLoginToAnalyzeMessage()}
                      required
                    />

                    <Label for="servings">Servings</Label>
                    <Input
                      type="number"
                      defaultValue={1}
                      min={1}
                      value={servings}
                      onChange={handleServingsChange}
                      className="primary mb-3 rounded"
                      placeholder="Servings Name"
                      onClick={() => showLoginToAnalyzeMessage()}
                      required
                    />

                    <Label for="ingredients">Ingredients</Label>
                    <Input
                      type="textarea"
                      value={ingredientList}
                      onChange={handleChange}
                      className="primary mb-3 rounded"
                      placeholder="Example: 2 cups of flour, 1/2 cup of sugar, 3 eggs..."
                      rows="5"
                      onClick={() => showLoginToAnalyzeMessage()}
                      required
                    />

                    {inputsEmpty && <Label className="text-danger">Dish Name and Ingredients are required.</Label>} {/* Display error label */}
                    <Button
                      color="primary"
                      size="lg"
                      onClick={handleRecipeAnalysis}
                      disabled={inputsEmpty || loading} // Disable the button while loading
                      block
                    >
                      {loading ? (
                        <div className="d-flex align-items-center justify-content-center">
                          <Spinner className="d-block m-2" color="warn" />
                          <span>Analyzing recipe...</span>
                        </div>
                      ) : (
                        <span>Analyze Recipe</span>
                      )}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={8}>
                <Card className="shadow rounded">
                  <CardBody>
                    <h5 className="font-weight-bold mb-4">Recipe Analysis (Credits: {recipesRemainingCount}):</h5>
                    <RecipeComponent ref={recipeRef} data={recipe || sampleRecipeData} isLoading={loading} isSample={isSample} />
                    {/* <p className="text-muted">Note: This is an AI, while it does it's best to be accurate, it may produce inaccurate information.</p> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
