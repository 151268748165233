import { calculateNutritionScore } from "../helper";

export interface NutrientAnalysis {
    unit: string;
    value: number;
}

export interface IngredientAnalysis {
    food: string;
    quantity: number;
    measure: string;
    calories: NutrientAnalysis;
    fat: NutrientAnalysis;
    salt: NutrientAnalysis;
    sugar: NutrientAnalysis;
    protein: NutrientAnalysis;
    carbohydrates: NutrientAnalysis;
}

export interface RecipeAnalysis {
    totalCalories: string;
    calories: string;
    fat: NutrientAnalysis;
    saturatedFat: NutrientAnalysis;
    cholesterol: NutrientAnalysis;
    salt: NutrientAnalysis;
    sugar: NutrientAnalysis;
    protein: NutrientAnalysis;
    carbohydrates: NutrientAnalysis;
    fiber: NutrientAnalysis;
    vitc: NutrientAnalysis;
    calcium: NutrientAnalysis;
    iron: NutrientAnalysis;
    potassium: NutrientAnalysis;
}

export interface Recipe {
    name: string;
    servings: number;
    recipeAnalysis: RecipeAnalysis;
    ingredientsAnalysis: IngredientAnalysis[];
    score: number
}

export function toRecipe(name: string, servings: number, response: any): Recipe {
    if (!response || !response.recipeAnalysis || !response.ingredientsAnalysis) {
        throw new Error('Invalid response structure');
    }

    const recipeData = {
        name,
        servings,
        recipeAnalysis: response.recipeAnalysis,
        ingredientsAnalysis: response.ingredientsAnalysis
            .map((ingredient: any) => ({
                food: ingredient.food,
                quantity: ingredient.quantity,
                measure: ingredient.measure,
                calories: ingredient.calories,
                fat: ingredient.fat,
                salt: ingredient.salt,
                sugar: ingredient.sugar,
                protein: ingredient.protein,
                carbohydrates: ingredient.carbohydrates
            }))
    };

    const recipe = {
        ...recipeData,
        score: calculateNutritionScore(recipeData)
    }
    return recipe;
}
