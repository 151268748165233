import image1 from "../../assets/images/blog/d9697ed3-623c-470e-9505-159d5b05efa4_inspire.png";
import image2 from "../../assets/images/blog/blog_post_2_main_image.png";
import image3 from "../../assets/images/blog/amol-tyagi-0juktkOTkpU-unsplash.jpg";
import image4 from "../../assets/images/blog/why_you_need_bank_statement_converter_ai.png";
/*
ChatGPT blog

Write me a blog post follow the following guidelines.

Topic:
How we secure your data

Instructions:
Optimize it for SEO, here are details. Make sure each paragraph is has the following className mb-4, also this is in react ts.
Make it sound authentic and real. Generate a title as well. Make sure it is in html format. Each paragraph should
be wrapped with a html p tag.

Background:
We take security of your data and information very seriously.

Here we detail how we secure your information.

First and foremost, we require you to have an account to use bank statement converter AI.
That provides you with several security benefits, it makes sure that when we store your
document for processing, it is encrypted with your account details.

We encrypt your data both in flight (explain what this means like I'm 5), and during rest (explain what this means).

We also delete any data you upload or we generate from analyzing your document in 24 hours automatically.

A little about encyption, because your documents are encrpted, that means, even if someone has a
link to your document, without your account information, they cannot access it.

Each URl generate to access your document must be signed, that is each request must include a signature. To calculate a signature, we first concatenate select request elements to form a string, referred to as the string to sign. We then use a signing key to calculate the hash-based message authentication code (HMAC) of the string to sign.

Our storage service will then re-create the signature by using the authentication information that is contained in the request. If the signatures match, it processes your request; otherwise, the request is rejected.

If you want to learn more about how we secure your data, don't hesitate to contact us at
RecipeCalorieAnalyzer@gmail.com

*/

export const blogArticleList = [
    {
        id: "1",
        date: "21 Oct, 2023",
        title: "",
        desc: "",
        img: image1,
        content: `<p className="mb-4">
        </p>`
    }
];
