const PERCENT_DV_PER_SERVING = 0.25;
interface INutritionValues {
    [key: string]: number;
}

function logarithmicScale(value: number, max: number): number {
    return 10 * Math.log10(1 + 9 * (value / max));
}

function sigmoid(x: number): number {
    return 1 / (1 + Math.exp(-x));
}

export function calculateNutritionScore(recipeData: any): number {
    // Define max recommended values for each nutrient per serving
    // These values are based on general daily values; they might need adjustments based on specific dietary recommendations.
    const maxValues: INutritionValues = {
        fat: 70 * PERCENT_DV_PER_SERVING,
        saturatedFat: 20 * PERCENT_DV_PER_SERVING,
        salt: 2300 * PERCENT_DV_PER_SERVING,
        sugar: 90 * PERCENT_DV_PER_SERVING,
        protein: 60 * PERCENT_DV_PER_SERVING,
        carbohydrates: 300 * PERCENT_DV_PER_SERVING,
        cholesterol: 300 * PERCENT_DV_PER_SERVING,
        fiber: 30 * PERCENT_DV_PER_SERVING,
        vitc: 90 * PERCENT_DV_PER_SERVING,
        calcium: 1300 * PERCENT_DV_PER_SERVING,
        iron: 18 * PERCENT_DV_PER_SERVING,
        potassium: 4700 * PERCENT_DV_PER_SERVING
    };

    const recipeAnalysis = recipeData.recipeAnalysis;

    let totalScore = 0;
    for (let nutrient in maxValues) {
        if (recipeAnalysis[nutrient]) {
            if (["fiber", "vitc", "protein", "calcium", "iron", "potassium"].includes(nutrient)) {
                // These are typically beneficial nutrients
                totalScore += calculateBeneficialScore(recipeAnalysis[nutrient].value, maxValues[nutrient]);
            } else {
                // Most other nutrients can be detrimental when over-consumed
                totalScore += calculateDetrimentalScore(recipeAnalysis[nutrient].value, maxValues[nutrient]);
            }
        }
    }
    // Calculate the average score
    const averageScore = totalScore / Object.keys(maxValues).length

    return Math.max(1, Math.min(10, Math.round(averageScore)));
}

function calculateDetrimentalScore(value: number, maxValue: number): number {
    return 10 * (1 - sigmoid((value - maxValue) / maxValue));
}

function calculateBeneficialScore(value: number, maxValue: number): number {
    return 10 * sigmoid((value) / maxValue);
}

export function replaceAbbreviations(inputString: string): string {
    if (!inputString) return inputString;
    inputString = inputString.trim();
    const abbreviationTable: Record<string, string> = {
        'T': 'tablespoon',
        'tbsp': 'tablespoon',
        'Tbsp': 'tablespoon',
        't': 'teaspoon',
        'tsp': 'teaspoon',
        'Tsp': 'teaspoon',
        'oz': 'ounce',
        'fl. oz': 'fluid ounce',
        'fl oz': 'fluid ounce',
        'floz': 'fluid ounce',
        'c': 'cup',
        'qt': 'quart',
        'pt': 'pint',
        'gal': 'gallon',
        'lb': 'pound',
        'LB': 'pound',
        'mL': 'milliliter',
        'g': 'gram',
        'grams': 'gram', // Plural form
        'kg': 'kilogram',
        'L': 'liter',
        'l': 'liter',
    };

    // Regular expression to match the abbreviations with quantity
    const regex = new RegExp(`(\\d+)\\s*(${Object.keys(abbreviationTable).join('|')})(?=\\s+\\w)`, 'g');

    // Replace abbreviations with their corresponding unabbreviated units
    const replacedString: string = inputString.replace(regex, (match: string, quantity: string, unit: string) => {
        const fullUnit = abbreviationTable[unit];
        // Add logic to handle plural forms
        const pluralUnit = fullUnit.endsWith('s') ? fullUnit : fullUnit + 's';
        return `${quantity} ${pluralUnit}`;
    });

    return replacedString;
}

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
